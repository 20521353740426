<template>
    <div class="hfull">
      <div class="p-3" style="display: flex; justifycontent: right">
        <button class="btn btn-danger" @click="close">X</button>
      </div>

      <b-container class="mt-5 pb-5">
        <b-row class="justify-content-center">
            <b-col md="12">
                <b-card style="background-color: #e8e8e8;">
                    <!-- <div style="display: flex;justify-content: center;align-items: center;">
                        <img src="../../../../public/img/brand/img-logo-color.png" alt="klhk"/>
                    </div> -->
                    <h2 class="my-5 text-center">Pilih Tipe Pendaftaran</h2>
                    <div style="display: flex;justify-content: center;align-items: center;gap: 30px;">
                      
                      <div class="card" @click="sparing">
                        <a class="card1">
                          <p>Pendaftaran Sparing</p>
                          <p class="small">Card description with lots of great facts and interesting details.</p>
                          <div class="go-corner">
                            <div class="go-arrow">
                              →
                            </div>
                          </div>
                        </a>
                      </div>

                      <div class="card" @click="waterRecycle">
                        <a class="card1">
                          <p>Pengolahan Kembali</p>
                          <p class="small">Card description with lots of great facts and interesting details.</p>
                          <div class="go-corner">
                            <div class="go-arrow">
                              →
                            </div>
                          </div>
                        </a>
                      </div>

                      <div class="card" @click="ujiKonek">
                        <a class="card1">
                          <p>Uji Konektivitas</p>
                          <p class="small">Card description with lots of great facts and interesting details.</p>
                          <div class="go-corner">
                            <div class="go-arrow">
                              →
                            </div>
                          </div>
                        </a>
                      </div>

                      <!-- <div class="card" @click="pelaporanProgress">
                        <a class="card1">
                          <p>Pelaporan Tahapan</p>
                          <p class="small">Card description with lots of great facts and interesting details.</p>
                          <div class="go-corner">
                            <div class="go-arrow">
                              →
                            </div>
                          </div>
                        </a>
                      </div> -->


                    </div>
                </b-card>
           </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  

  <script>
  export default {
    name:"pilihPendaftaran",
    data() {
        return {

        }
    },
    methods: {
        close() {
            this.$router.push("/Home");
        },
        sparing() {
            this.$router.push("/sparing/pendaftaran-baru");
        },
        ujiKonek() {
            this.$router.push("/uji-konektivitas/pendaftaran-baru");
        },
        waterRecycle() {
          this.$router.push("/sparing/pendaftaran-pengolahan-air-kembali");
        },
        pelaporanProgress() {
          this.$router.push("/pelaporan-progress/pendaftaran-baru");
          
        }
    },
  }
  </script>

  
  <style scoped>

  .card p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
}

.card p.small {
  font-size: 14px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #fff;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card2:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #ccc;
  background-color: white;
}

.card2:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card2:hover:before {
  transform: scale(2.15);
}

.card3 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card3 .go-corner {
  opacity: 0.7;
}

.card3:hover {
  border: 1px solid #00838d;
  box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
  z-index: 500;
}

.card3:hover p {
  color: #00838d;
}

.card3:hover .go-corner {
  transition: opactiy 0.3s linear;
  opacity: 1;
}

.card4 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #fff;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #ccc;
}

.card4 .go-corner {
  background-color: #00838d;
  height: 100%;
  width: 16px;
  padding-right: 9px;
  border-radius: 0;
  transform: skew(6deg);
  margin-right: -36px;
  align-items: start;
  background-image: linear-gradient(-45deg, #8f479a 1%, #dc2a74 100%);
}

.card4 .go-arrow {
  transform: skew(-6deg);
  margin-left: -2px;
  margin-top: 9px;
  opacity: 0;
}

.card4:hover {
  border: 1px solid #cd3d73;
}

.card4 h3 {
  margin-top: 8px;
}

.card4:hover .go-corner {
  margin-right: -12px;
}

.card4:hover .go-arrow {
  opacity: 1;
}


  /* end of card */
  .el-table .el-table__header-wrapper thead th .cell {
    justify-content: flex-start;
  }
  
  .myshadow {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  .step-progress .bar {
    background-color: #1b674c !important;
  }
  
  .step-button {
    display: inline-block;
    font-weight: 600;
    color: #1b674c;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }
  
  .step-button-next {
    background-color: #1b674c !important;
    color: #fff;
  }
  
  .step-pills .step-item.active {
    border: 1px solid #1b674c !important;
  }
  
  .step-pills .step-item {
    margin-right: 5px;
  }
  
  .step-item {
    position: relative;
    width: 200px;
    height: 100px;
  }
  
  .tabLabel {
    position: absolute;
    bottom: 10px;
    left: 10px;
    line-height: 17px;
  }
  
  .popup-modal-background {
    background: #fff;
  }
  
  .mdl-contentpopup {
    background: #fff;
    overflow-y: auto;
    max-height: 90%;
  }
  </style>
  